<template>
    <div class="app">
        <div class="container mx-auto pt-20 pb-20">
            <div class="flex">
                <div class="w-8/12">
                    <h1 class="font-black text-6xl text-green mb-6"><strong class="text-white">Wertetest</strong> Wertepole</h1>
                    <p class="text-2xl leading-snug">Welche Werte sind in Dir besonders stark ausgeprägt und zu welchem inneren Motivationsfeld gehören sie.
                        Überwiegen bei Dir oder in Deinem Unternehmen Stimulanz, Dominanz oder Balance? Oder bist Du doch eher
                        Abenteurer, Disziplinierter oder der Genießer?</p>
                </div>
            </div>
        </div>

        <div class="container mx-auto py-20">
            <div class="flex items-center">
                <div class="w-2/12">
                    <div class="text-center">BILD</div>
                </div>
                <div class="w-8/12">
                    <div class="flex justify-between items-center">
                        <div class="text-center w-40">{{ questions[currentQuestion][0] }}</div>
                        <div class="flex-1">
                            <ul class="steps">
                                <li class="steps__item" :class="['steps__item--' + ((index-steps-1) * -1), selectedValue <= (index-20-1) ? 'steps__item--active' : '']" v-for="index in steps" :key="index" @click="setValue(index - steps - 1)"></li>
                                <li class="steps__item" :class="['steps__item--' + index, index <= selectedValue ? 'steps__item--active' : '']" v-for="index in steps" :key="index" @click="setValue(index)"></li>
                            </ul>
                            <Slider v-model="selectedValue" :min="steps*-1" :max="steps" />
                        </div>
                        <div class="text-center w-40">{{ questions[currentQuestion][1] }}</div>
                    </div>
                </div>
                <div class="w-2/12">
                    <div class="text-center">BILD</div>
                </div>
            </div>
        </div>

        <div class="container mx-auto">
            <div class="my-2">Wertepaar {{ currentQuestion+1 }} von {{ questions.length }}</div>
            <div class="w-full h-5 relative rounded-lg overflow-hidden bg-bluegray-light">
                <div class="h-full absolute top-0 left-0 bg-green transition-all ease-in-out" :style="{ width: progress + '%' }"></div>
            </div>
        </div>

        <div class="container mx-auto py-6">
            <div class="flex justify-between">
                <div class="flex-shrink">
                    <button class="px-6 py-3 rounded uppercase bg-bluegray-light" v-if="currentQuestion!==0" @click="prevQuestion()">zurück</button>
                </div>
                <div class="flex-shrink">
                    <button class="px-6 py-3 rounded uppercase bg-green" @click="nextQuestion()">weiter</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from '@vueform/slider'

export default {
    name: "App",
    components: {
        Slider,
    },
    data() {
        return {
            questions: [
                ['außergewöhnlich', 'normal'],
                ['schnell', 'gründlich'],
                ['experimentell', 'routiniert'],
                ['impulsiv', 'überlegt'],

                ['spontan', 'geplant'],
                ['mutig', 'zurückhaltend'],
                ['laut', 'leise'],
                ['dynamisch', 'statisch'],

                //Dominanz - Fantasie / Genuss
                ['hart', 'kooperativ'],
                ['fordernd', 'gebend'],
                ['solitär', 'kollektiv'],
                ['elitär', 'emphatisch'],
                ['durchsetzungsstark', 'angepasst'],
                ['steile Hierachie', 'flache Hierarchie'],
                ['herausfordernd', 'gemütlich'],
                ['diszipliniert', 'locker'],

                //Disziplin - Kontrolle / Stimulanz
                ['strukturiert', 'fantasievoll'],
                ['nüchtern', 'leidenschaftlich'],
                ['spezialisiert', 'vielseitig'],
                ['kontrolliert', 'intuitiv'],
                ['asketisch', 'großzügig'],
                ['aufopferungsvoll', 'hedonistisch'],
                ['traditionell', 'exotisch'],
                ['pflichtbewusst', 'individualistisch']
            ],
            steps: 20,
            selectedValue: 0,
            currentQuestion: 0,
            progress: 0,
            result: [],
            resultChart: [],
            testDone: false
        }
    },
    created() {
        for (var i = 0; i < (this.questions.length * 2); i++) {
            this.resultChart[i] = 0;
        }
    },
    watch: {
        currentQuestion(newValue) {
            this.progress = newValue / this.questions.length * 100;

            if (newValue === (this.questions.length - 1)) {
                this.testDone = true;
            }
        },
    },
    computed: {
        classObject: function () {
            return {
                active: this.isActive && !this.error,
                'text-danger': this.error && this.error.type === 'fatal'
            }
        }
    },
    methods: {
        selectOption: function (value) {
            let alpha = this.currentQuestion,
                beta = (this.questions.length + this.currentQuestion);

            if (value <= 3) {
                this.resultChart[alpha] = value;
            } else {
                this.resultChart[beta] = (value - 3);
            }

            this.currentQuestion++;
        },
        setValue: function(index){
            this.selectedValue = index;
        },
        prevQuestion: function(){
            this.currentQuestion--;
            this.selectedValue = this.result[this.currentQuestion];
        },
        nextQuestion: function(){
            this.result[this.currentQuestion] = this.selectedValue;
            this.selectedValue = this.result[this.currentQuestion+1] ? this.result[this.currentQuestion+1] : 0;

            /*
            let alpha = this.currentQuestion,
                beta = (this.questions.length + this.currentQuestion);

            if (this.selectedValue <= 3) {
                this.resultChart[alpha] = this.selectedValue;
            } else {
                this.resultChart[beta] = (this.selectedValue - 3);
            }

             */

            this.currentQuestion++;
        }
    }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style lang="css">

.steps {
    display: flex;
    justify-content: space-between;
}

.steps__item {
    background: #ccc;
    width: 8px;
    height: 24px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.steps__item::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: block;
    position: absolute;
    bottom: 0;
    transition: .2s ease-in-out;
}

.steps__item--active::after {
    background: #41b883;
}

.steps__item--active:first-child::after,
.steps__item--active:last-child::after {
    height: calc(100% + 48px);
}

.steps__item--active:nth-child(2)::after,
.steps__item--active:nth-last-child(2)::after {
    height: calc(100% + 24px);
}

.steps__item--active:nth-child(3)::after,
.steps__item--active:nth-last-child(3)::after {
    height: calc(100% + 12px);
}

.steps__item--active:nth-child(4)::after,
.steps__item--active:nth-last-child(4)::after {
    height: calc(100% + 6px);
}

.steps__item--active:nth-child(5)::after,
.steps__item--active:nth-last-child(5)::after {
    height: calc(100% + 3px);
}

.slider-tooltip {
    display: none;
}
</style>
